import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Sale from "../components/sale.vue";
import Top from "../components/top.vue";
import Bonus from "../components/bonus.vue";
import Career from "../components/career.vue";
import State from "../components/state.vue";
import WDay from "../components/working_days.vue";
// import Login from "../components/login.vue";
import Admin from "../components/index-admin.vue";
import Auth from "../components/auth_refresh_test.vue";
import SalaryList from "../components/salary/salary_list.vue";
import ABC from "../components/abc/abc.vue";
import ABC_Collection from "../components/abc/collection.vue";
import Integration from "../components/integration/index.vue";
import Sellers_Admin from "../components/sellers_admin.vue";
import Stenova from "../components/stenova.vue";
import Monitor from "../components/monitor.vue";
import Marketing from "../components/marketing.vue";
import Orders from "../components/orders.vue";
import Inventory from "../components/inventory.vue";
import Wages from "../components/wages.vue";

import Home2022 from "../components/2022/Home.vue";
import Salary2022 from "../components/2022/Salary.vue";
import Sale2022 from "../components/2022/Sale.vue";
// import Inventory2022 from "../components/2022/Inventory.vue";
import Orders2022 from "../components/2022/Orders.vue";
// import Login2022 from "../components/2022/Login.vue";
import BlogGroup from "../components/blog_group.vue"; // для смешения локальных и общих статей
import Article from "../components/article.vue"; // для редактирования статьи
// import Blog from "../components/blog.vue"; // oboi.ru - admin - read only
import Blog2022 from "../components/2022/Blog.vue"; // for seller - read only
// import Indicators2022 from "../components/2022/Indicators.vue";
import Bonus2022 from "../components/2022/Bonus.vue";
import Career2022 from "../components/2022/Career.vue";
import Top2022 from "../components/2022/Top.vue";
import Page2022 from "../components/2022/Page.vue";
// import HomeAdmin2022 from "../components/2022/Home-admin.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    components: {
      xs: store.state.access.any == "admin" ? Admin : Home2022,
      default: store.state.access.any == "admin" ? Admin : State,
    },
  },
  {
    path: "/salary",
    name: "Salary",
    components: {
      xs: store.state.access.any == "admin" ? SalaryList : Salary2022,
      default: store.state.access.any == "admin" ? SalaryList : State,
    },
  },
  // { path: "/2022/sale", name: "Sale", component: Sale2022 },
  // { path: "/2022/login", name: "Login", component: Login2022 },
  // { path: "/2022/blog", name: "Blog", component: Blog2022 },
  // { path: "/2022/bonus", name: "Bonus", component: Bonus2022 },
  // { path: "/2022/career", name: "Career", component: Career2022 },
  // { path: "/2022/top", name: "Top", component: Top2022 },
  { path: "/page", name: "Page", component: Page2022 },
  {
    path: "/indicators",
    name: "Indicators",
    component: () => import("../components/2022/Indicators.vue"),
  },
  // { path: "/2022/inventory", name: "Inventory", component: Inventory2022 },
  {
    path: "/",
    redirect: ~[
      ...new Set(Object.entries(store.state.access).map((el) => el[1])),
    ].indexOf("producer")
      ? "/producer"
      : "/home",
  },
  {
    path: "/admin",
    component: () => import("../components/index-admin.vue"),
    name: "Admin",
    // component: Admin
  },
  {
    path: "/admin2022",
    component: () => import("../components/2022/Home-admin.vue"),
    name: "Admin2022",
    // component: Admin
  },
  {
    path: "/abc2022",
    component: () => import("../components/2022/Abc.vue"),
    name: "Abc2022",
    // component: Admin
  },
  {
    path: "/turnover2022",
    component: () => import("../components/2022/AbcTurnover.vue"),
    name: "Trunover2022",
    // component: Admin
  },
  {
    path: "/producer",
    component: () => import("../components/2022/Producer.vue"),
    name: "Producer",
    // component: Admin
  },
  {
    path: "/monitor",
    name: "Monitor",
    component: Monitor,
  },
  {
    path: "/marketing",
    name: "Marketing",
    component: Marketing,
  },
  {
    path: "/sale",
    name: "Sale",
    components: { default: Sale, xs: Sale2022 },
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   components: { default: Login, xs: Login2022 }
  // },
  // {
  //   path: "/logout",
  //   name: "Logout",
  //   components: { default: Login, xs: Login2022 }
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   components: { default: Login }
  // },
  // {
  //   path: "/logout",
  //   name: "Logout",
  //   components: { default: Login }
  // },
  {
    path: "/top",
    name: "Top",
    components: { default: Top, xs: Top2022 },
  },
  {
    path: "/bonus",
    name: "Bonus",
    components: { default: Bonus, xs: Bonus2022 },
  },
  {
    path: "/career",
    name: "Career",
    components: { default: Career, xs: Career2022 },
  },
  {
    path: "/state",
    name: "State",
    component: State,
  },
  {
    path: "/graph",
    name: "WDay",
    component: WDay,
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/blog",
    name: "Blog",
    components: { default: BlogGroup, xs: Blog2022 },
  },
  {
    path: "/blog/article/:id",
    name: "Article",
    component: Article,
  },

  {
    path: "/salary_list",
    name: "SalaryList",
    component: SalaryList,
  },
  {
    path: "/abc",
    name: "ABC",
    component: ABC,
  },
  {
    path: "/abc/collection",
    name: "ABC_Collection",
    component: ABC_Collection,
  },
  {
    path: "/integration",
    name: "Integration",
    component: Integration,
  },
  {
    path: "/sellers",
    name: "Sellers_Admin",
    component: Sellers_Admin,
  },
  {
    path: "/stenova",
    name: "Stenova",
    component: Stenova,
  },
  {
    path: "/orders",
    name: "Orders",
    components: { default: Orders, xs: Orders2022 },
  },
  {
    path: "/wages",
    name: "Wages",
    component: Wages,
  },

  {
    path: "/inventory",
    name: "Inventory",
    components: { default: Inventory, xs: Inventory },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // if (to.name == "Login") return next();
  // let refresh = localStorage.getItem("refresh_token");
  // if (refresh) {
  if (!to.matched[0].components.xs) return next();
  if (!store.state.monitor) {
    setTimeout(() => {
      if (store.state.monitor == "xs")
        to.matched[0].components.default = to.matched[0].components.xs;
      next();
    }, 100);
  } else if (store.state.monitor == "xs") {
    to.matched[0].components.default = to.matched[0].components.xs;
    return next();
  } else return next();
  // }
  // return next({ path: "/login" });
  // else return next({ path: "/home" });
});

export default router;
