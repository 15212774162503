<template>
  <v-main :style="style.background" class="pt-0 pb-0">
    <v-card
      :style="{ ...style.neobtn, 'border-radius': '0px' }"
      class="mx-auto pt-4 pb-12"
      width="1160"
      height="100%"
    >
      <v-chip-group
        column
        active-class="white--text"
        class="d-print-none ml-8 mt-n2"
      >
        <div class="py-3">
          <v-chip
            active-class="white--text"
            :style="{ ...style.neobtn, 'border-radius': '10px' }"
            v-for="(key, chip) in chips"
            :key="key"
            @click="active_chip = key != active_chip ? key : ''"
            :color="key == active_chip ? 'orange lighten-2' : ''"
          >
            {{ chip }}
          </v-chip>
        </div>
      </v-chip-group>

      <!-- тестовый вариант переработок -->

      <v-row v-if="active_chip == 'revision' || active_chip == 'working_hours'">
        <v-col>
          <v-card :style="style.neocard" class="px-md-8 px-6 mx-6">
            <v-row v-if="active_chip == 'revision'">
              <v-col class="ma-0">
                <v-simple-table dense class="grey6">
                  <template>
                    <thead>
                      <tr>
                        <th
                          v-for="name in names"
                          :key="name"
                          class="grey--text text-left subtitle-1"
                        >
                          {{ name }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="user in (data || [])
                          .map((el) => el.users)
                          .flat()"
                        :key="user.id"
                        class="blueGrey--text"
                      >
                        {{
                          user.name
                        }}
                        <td class="py-2">
                          <tr class="blueGrey--text">
                            <span
                              >{{
                                [user.pay, ...user.payOther].reduce(
                                  (acc, el) => acc + el.SData.hours,
                                  0
                                ) || ""
                              }}
                            </span>
                          </tr>
                        </td>

                        <td class="py-2">
                          <tr class="blueGrey--text">
                            <span v-if="user.extraHours[0]">
                              {{ user.extraHours[0] }}</span
                            >
                          </tr>
                        </td>

                        <td class="py-2">
                          <tr class="blueGrey--text">
                            <span v-if="user.extraHours[1]">
                              {{ user.extraHours[1] | date2 }}</span
                            >
                          </tr>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- тестовый вариант редактирования часов-->
      <v-btn
        v-show="active_chip == 'working_hours'"
        color="orange lighten-2"
        dark
        class="mx-6 mb-10"
        :disabled="!check_work_hours.length"
        @click="add_work_hours()"
      >
        сохранить
      </v-btn>
      <v-row v-if="active_chip == 'working_hours'">
        <v-col>
          <v-card :style="style.neocard" class="px-md-8 px-6 mx-6">
            <v-row>
              <v-col>
                <v-simple-table dense class="grey6">
                  <template>
                    <thead>
                      <tr>
                        <th>
                          <v-btn
                            fab
                            text
                            small
                            color="grey"
                            @click="
                              next_years = next_years.map((el) =>
                                String(+el - 1)
                              )
                            "
                          >
                            <v-icon small> mdi-chevron-left </v-icon>
                          </v-btn>
                        </th>
                        <th
                          v-for="year in next_years"
                          class="grey--text text-left subtitle-1"
                          :key="year"
                        >
                          <div>{{ year }}</div>
                        </th>
                        <v-btn
                          fab
                          text
                          small
                          color="grey"
                          class="text-left"
                          @click="
                            next_years = next_years.map((el) => String(+el + 1))
                          "
                        >
                          <v-icon small> mdi-chevron-right </v-icon>
                        </v-btn>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(month, idx) in months"
                        :key="idx"
                        class="blueGrey--text my-2"
                      >
                        {{
                          month
                        }}
                        <td v-for="(elem, idx1) in next_years" :key="idx1">
                          <input
                            type="text"
                            class="blueGrey--text"
                            @input="
                              working_hours_new = { ...working_hours_new };
                              working_hours_new[[idx + 1, elem]] = checkValue(
                                working_hours_new[[idx + 1, elem]]
                              );
                            "
                            v-model="working_hours_new[[idx + 1, elem]]"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!--    кол-во сотрудников, общая и средняя зп -->

      <v-row
        class="grey--text d-flex mr-16 mr-md-0 mb-3"
        v-if="active_chip !== 'working_hours' && active_chip !== 'revision'"
      >
        <v-col
          md="1"
          v-if="data.length"
          class="d-flex flex-column align-end ml-3 grey--text text--lighten-1 mr-5 mr-md-0"
        >
          <div class="text-24">
            <v-icon class="ml-1" color="grey lighten-1">people</v-icon>
            {{
              (people = data
                .map((el) => el.users)
                .flat()
                .reduce((a, b) => a + (b.stable ? 1 : 0), 0))
            }}
          </div>
          <div class="text-18 mt-n3">
            {{
              (money = data.reduce((a, b) => a + b.stable, 0)) == 0
                ? 0
                : (money / people) | money
            }}
          </div>
          <div class="text-12 mt-n2 orangeDark--text">средняя зп</div>
        </v-col>

        <v-col md="2" v-if="data.length" class="d-flex flex-column align-end">
          <div class="text-40 mt-n1 grey--text text--lighten-1">
            {{ data.reduce((a, b) => a + b.stable, 0) | money }}
          </div>
          <div class="text-12 mt-n4 orangeDark--text">общая зп</div>
        </v-col>
        <v-col
          md="2"
          cols="7"
          v-if="data.length"
          class="d-flex flex-column align-end ms-md-n12 ms-n6"
        >
          <div
            class="text-40 mx-md-0 mt-md-n1 grey--text text--lighten-1 mt-n5"
          >
            <span class="text-24 mr-n2">%</span>
            {{
              (moneyI = data.reduce((a, b) => a + b.money, 0)) > 0
                ? ((money / moneyI) * 100).toFixed(2)
                : 0
            }}
          </div>
          <div class="text-12 mt-n4 orangeDark--text text-end">от оборота</div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="2">
          <!--  кнопка согласования -->
          <v-btn
            large
            v-if="access.any == 'admin'"
            elevation="0"
            right
            :style="{
              border: '1px solid rgba(255, 130, 130, 0.4)',
              'border-radius': '10px',
            }"
            class="red--text text--lighten-2 ml-md-n2 ml-6"
            v-show="!appCorrect"
            @click="
              approval.approval = true;
              add_approval();
            "
            >согласовать
          </v-btn>
          <!--   кем согласовано -->
          <v-card
            v-show="appCorrect"
            :style="style.btnagree"
            width="220"
            class="text-center ml-md-n6 mt-n3 ml-6"
          >
            <div class="green--text text--lighten-1 text-24">
              cогласовано
              <div class="mt-n3 grey--text text-16">
                {{ approval.username }}
              </div>
              <div class="mt-n2 grey--text text-14">
                {{ approval.date | date1 }}
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <template
        v-if="
          Object.keys(data).length > 0 &&
          active_chip !== 'working_hours' &&
          active_chip !== 'revision'
        "
      >
        <v-data-iterator
          v-for="shop in data.filter((el) => el.stable)"
          :key="shop.email"
          :items="shop.users"
          hide-default-footer
        >
          <template v-slot:header>
            <v-toolbar
              flat
              color="white"
              class="mb-2 mx-2 grey--text mt-n1 pa-0"
            >
              <!-- ОТРИСОВАТЬ -->
              <v-col md="6" cols="12">
                <div class="md-text-24 mr-md-10 mr-3">
                  {{ `${shop.ID} ${shop.name}` }}
                </div>
                <!-- <div class=" md-text-24 d-flex">
              {{shop.stable | money}}<span class="grey4--text ml-1">₽</span></div> -->
              </v-col>
              <v-col
                cols="2"
                v-if="shop.users.length"
                class="d-flex flex-column align-end ml-3 grey--text text--lighten-1 mr-5 mr-md-0"
              >
                <div class="text-18 mt-n2">
                  <span class="grey4--text pr-8">
                    <v-icon class="ml-1" color="grey5">people</v-icon>
                    {{
                      (people = shop.users.reduce(
                        (a, b) => a + (b.stable ? 1 : 0),
                        0
                      ))
                    }}
                  </span>
                  {{
                    (money = shop.users.reduce((a, b) => a + b.stable, 0)) == 0
                      ? 0
                      : (money / people) | money
                  }}
                </div>
                <div class="text-12 mt-n2 grey4--text">средняя зп</div>
              </v-col>

              <v-col cols="2" v-if="shop" class="d-flex flex-column align-end">
                <div class="text-22 mt-n1 grey--text text--lighten-1">
                  {{ shop.stable | money }}
                </div>
                <div class="text-12 mt-n2 grey4--text">общая зп</div>
              </v-col>

              <v-col
                cols="2"
                v-if="shop"
                class="d-flex flex-column align-end ms-md-n12 ms-n6"
              >
                <div
                  class="text-22 mx-md-0 mt-md-n1 grey--text text--lighten-1 mt-n5"
                >
                  <span class="text-18 mr">%</span>
                  {{
                    shop.money > 0 ? ((money / shop.money) * 100).toFixed(2) : 0
                  }}
                </div>
                <div class="text-12 mt-n2 grey4--text text-end">от оборота</div>
              </v-col>
            </v-toolbar>
          </template>

          <template v-slot:default="props">
            <v-row class="px-md-5 px-2 mb-16 pt-6">
              <v-col
                v-for="item in props.items.filter((el) => el.stable)"
                :key="shop.ID + item.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <!--      карточка сотрудника -->
                <v-card :style="style.neobtn" min-height="240">
                  <v-card-title class="d-flex">
                    <!--      ТУТ КНОПУЛЯ ДЛЯ ПЕРЕХОДА НА ЗАРПЛАТНУЮ СТРАНИЦУ -->
                    <a>
                      <div
                        class="mr-auto blueGrey--text text-20"
                        @click="
                          show_state_w = true;
                          change_shop(shop.ID);
                          change_user({ id: item.id });
                        "
                      >
                        {{ item.name.slice(0, 20) }}
                      </div></a
                    >
                    <!-- <div class="text&#45;h5">{{ item.stable }} ₽</div> -->
                  </v-card-title>
                  <v-card-subtitle class="d-flex">
                    <div class="mr-auto blueGrey--text">{{ item.phone }}</div>
                    <div class="text-h5 orangeDark--text">
                      {{ item.stable | money }}
                    </div>
                    <div class="text-h5 ml-1 grey4--text">₽</div>
                  </v-card-subtitle>
                  <v-divider></v-divider>

                  <v-row class="mx-1 grey4--text text-14">
                    <v-col>маг-н</v-col>
                    <v-col>смен</v-col>
                    <v-col>часов</v-col>
                    <v-col class="text-right">денег</v-col>
                  </v-row>

                  <v-row
                    v-for="s in (pays = [item.pay, ...item.payOther].filter(
                      (el) => el.pay
                    ))"
                    :key="s.shop + item.phone"
                    class="mx-1 mt-n3 text-14 mb-2"
                  >
                    <v-col>
                      <v-row>
                        <v-col class="py-0">{{ s.shop }}</v-col>
                        <v-col class="py-0">{{ s.SData.count }}</v-col>
                        <v-col class="py-0">{{ s.SData.hours }}</v-col>
                        <v-col class="text-right py-0">{{
                          s.pay | money
                        }}</v-col>
                      </v-row>
                      <v-row class="blueLight--text">
                        <v-col class="mt-n2 py-2">оклад</v-col>
                        <v-col class="text-end mt-n2 pt-2">{{
                          s.stable | money
                        }}</v-col>
                      </v-row>
                      <v-row class="blueLight--text">
                        <v-col cols="8" class="mt-n3 pt-1">
                          ежедневные бонусы</v-col
                        >
                        <v-col class="text-end mt-n3 pt-1">{{
                          (s.dailyplan + s.avercheck) | money
                        }}</v-col>
                      </v-row>
                      <v-row class="blueLight--text">
                        <v-col cols="8" class="mt-n3 pt-1">
                          ежемесячные бонусы</v-col
                        >
                        <v-col class="text-end mt-n3 pt-1">{{
                          (s.personal +
                            s.bonusroll +
                            s.extra +
                            s.conversion +
                            s.special_products)
                            | money
                        }}</v-col>
                      </v-row>
                      <v-row
                        v-for="elem in s.corrections"
                        :key="String(elem.date)"
                      >
                        <v-col cols="9" class="mt-n3 blueLight--text pt-1"
                          >корректировка
                          <div
                            class="text-12 grey3--text mt-n2 d-flex flex-column pt-1"
                          >
                            {{ elem.describe }}
                          </div>
                        </v-col>
                        <v-col
                          class="text-end mt-n3 pt-1"
                          :class="
                            elem.amount < 0
                              ? 'red--text text--lighten-3'
                              : 'blueLight--text'
                          "
                        >
                          {{ elem.amount | money }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!--  <v-row class="blueLight--text mt-n6">
                    <v-col>
                      <div class="d-flex flex-row justify-space-between mx-3">
                        оклад
                        <div >{{s.stable | money}}</div>
                      </div>
                      <div class="d-flex flex-row justify-space-between mx-3">
                        ежедневные бонусы
                        <div >
                          {{s.dailyplan + s.avercheck | money}}</div>
                        </div>
                      <div class="d-flex flex-row justify-space-between mx-3">
                          ежемесячные бонусы
                        <div >
                            {{s.personal + s.bonusroll + s.extra | money}}</div>
                        </div> -->
                  <!--       внесенные корректировки -->
                  <!--   <div v-for="elem in s.corrections"
                                  :key="String(elem.date)">
                          <div  class="d-flex flex-row justify-space-between mx-3">
                              корректировка продаж
                            <div 
                            :class="elem.amount < 0 ? 'red--text text--lighten-3' : 'blueLight--text'">{{elem.amount | money}}
                            </div>
                          </div>
                          <div class="text-12 grey4--text mt-n2 d-flex flex-column mx-3">
                             {{elem.describe}}
                          </div>
                      </div> -->
                  <!--        строка корректировки -->
                  <!--           <template v-if="correct.amount &&
                               s.shop == correct.shopID &&
                               correct.user==item.id">
                    <div  class="d-flex flex-row justify-space-between">
                        корректировка продаж
                      <div class="mx-4 mx-md-0"
                      :class="correct.amount < 0  ? 'red--text text--lighten-3' : 'blueLight--text'">{{correct.amount | money}}
                      </div>
                    </div>
                      <div class="text-12 grey4--text mt-n2 d-flex flex-column">
                         {{correct.describe}}
                      </div>
                    </template>
                    </v-col>  
                </v-row> -->

                  <v-row>
                    <v-col>
                      <!--    кнопка добавления корректировки -->
                      <v-card-actions>
                        <v-btn
                          block
                          text
                          small
                          @click="
                            dialog = true;
                            correct.user = item.id;
                            itemsShops = [item.pay, ...item.payOther]
                              .filter((el) => el.pay)
                              .map((el) => el.shop);
                          "
                          class="mt-n2 blueGrey--text pa-0"
                        >
                          добавить корректировку
                        </v-btn>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>

        <!--  диалог зарплатной страницы -->
        <v-dialog
          fullscreen
          transition="dialog-bottom-transition"
          v-model="show_state_w"
        >
          <v-card>
            <v-toolbar dark :style="{ 'z-index': '203' }" color="orangeDark">
              <v-btn icon dark @click="show_state_w = false">
                <v-icon>fas fa-arrow-down</v-icon>
              </v-btn>
              <!-- листалка дат для зарплатной карточки на index-admin -->
              <v-row>
                <v-col cols="3" class="pl-6 text-right py-0">
                  <v-btn text @click="set_date(local_month - 1, local_year)">
                    <v-icon small>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center py-0">
                  {{ months[local_month - 1] }} {{ local_year }}
                </v-col>
                <v-col cols="3" class="text-left py-0">
                  <v-btn text @click="set_date(local_month + 1, local_year)">
                    <v-icon small>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!-- полоса загрузки -->
              <v-progress-linear
                indeterminate
                absolute
                v-if="loading"
                height="5"
                color="white"
                class="align-self-end mb-n1"
              ></v-progress-linear>
            </v-toolbar>
            <state :local_month="local_month" :local_year="local_year"></state>
          </v-card>
        </v-dialog>

        <!--  диалог добавления корректировки-->
        <v-dialog v-model="dialog" width="500">
          <v-card height="300" class="pt-8">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-combobox
                    v-model="correct.shopID"
                    :items="itemsShops"
                    :search="search"
                    label="Магазин"
                    outlined
                    dense
                    hide-details
                    :style="{ ...style.neocard, 'border-radius': '10px' }"
                    color="blueLight"
                  >
                  </v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    hide-details
                    :autofocus="correct.describe ? true : false"
                    id="describe"
                    :style="{ ...style.neocard, 'border-radius': '10px' }"
                    label="описание"
                    maxlength="20"
                    v-model.trim="correct.describe"
                    dense
                    clearable
                    color="blueLight"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    hide-details
                    ref="form"
                    :autofocus="correct.amount ? true : false"
                    id="amount"
                    :rules="[(v) => !isNaN(v) || 'Допустимы только цифры']"
                    @input="validateCorrect"
                    :style="{ ...style.neocard, 'border-radius': '10px' }"
                    label="сумма"
                    v-model.trim="correct.amount"
                    :color="correct.amount < 0 ? 'red lighten-3' : 'blueLight'"
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <!--  <v-card-actions class="  justify-md-space-between align-end"> -->
              <v-btn
                :style="{
                  ...style.neobtn,
                  ...style.greenborder,
                  'border-radius': '8px',
                }"
                class="green--text text--lighten-3 mr-md-16 mt-md-6 mt-4"
                :disabled="
                  checkNumbers &&
                  Object.values(correct).filter((el) => el).length ==
                    Object.keys(correct).length
                    ? false
                    : true
                "
                @click="
                  correct.amount < 0 ? (correct.amount *= -1) : '';
                  add_correct().then(() => (dialog = false));
                "
              >
                прибавить к зп
              </v-btn>
              <v-btn
                :style="{
                  ...style.neobtn,
                  ...style.redborder,
                  'border-radius': '8px',
                }"
                class="red--text text--lighten-3 ml-md-14 mt-4 mt-md-6"
                :disabled="
                  checkNumbers &&
                  Object.values(correct).filter((el) => el).length ==
                    Object.keys(correct).length
                    ? false
                    : true
                "
                @click="
                  correct.amount > 0 ? (correct.amount *= -1) : '';
                  add_correct().then(() => (dialog = false));
                "
              >
                вычесть из зп
              </v-btn>
              <!--  </v-card-actions> -->
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </v-card>
  </v-main>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import State from "../../components/state.vue";

export default {
  name: "SalaryList",

  components: {
    state: State,
  },
  data: () => ({
    data: [],
    itemsPerPage: 4,
    dialog: false,
    search: null,
    itemsShops: [],
    appCorrect: false,
    correct: {
      amount: "",
      describe: "",
      shopID: "",
      user: undefined,
    },
    approval: {},
    chips: {
      зарплата: "salary",
      переработки: "revision",
      "редактирование рабочих часов": "working_hours",
    },
    active_chip: "",
    names: [
      "ФИО",
      "всего часов",
      "переработка (сколько часов)",
      "переработка (с какого числа)",
    ],
    start: "2020-11-30",
    startMenu: false,
    next_years: [...Array(4)].map((el, idx) =>
      String(new Date().getFullYear() + idx)
    ),
    show_state_w: false,
    local_month: new Date().getMonth() + 1,
    local_year: new Date().getFullYear(),
    working_hours: [],
    working_hours_new: {},
    checkNumbers: false,
  }),

  computed: {
    ...mapState([
      "year",
      "month",
      "back_domen",
      "access",
      "shop",
      "style",
      "username",
      "all_shop_info",
      "fullname",
      "months",
      "loading",
    ]),

    check_work_hours() {
      // несохраненные изменения в часах
      let data1 = this.working_hours.reduce(
        (acc, el) =>
          el.hours ? { ...acc, [[+el.month, +el.year]]: el.hours } : acc,
        {}
      );
      let data2 = Object.entries(this.working_hours_new)
        .filter(
          (el) =>
            (+data1[[+el[0].split(",")[0], +el[0].split(",")[1]]] || 0) !=
            +el[1]
        )
        .map((el) => {
          return {
            month: +el[0].split(",")[0],
            year: +el[0].split(",")[1],
            hours: +el[1],
          };
        });
      return data2;
    },
  },

  methods: {
    ...mapMutations([
      "change_user", // изменение глобального пользователя
      "change_shop", // изменение глобального магазина
    ]),

    set_date(month, year) {
      // расчет локальный даты
      let new_year = month < 1 ? year - 1 : month > 12 ? year + 1 : year;
      let new_month = new Date(year, month - 1, 1).getMonth() + 1;
      if (this.local_month != new_month) this.local_month = new_month;
      if (this.local_year != new_year) this.local_year = new_year;
    },

    validateCorrect() {
      this.checkNumbers = this.$refs.form.validate();
    },

    async get_data() {
      // получение данных
      this.data = {};
      let admin = Object.entries(this.access).reduce(
        (acc, [k, v]) => (v == "admin" ? { ...acc, [k]: v } : acc),
        {}
      );
      if (this.access.any != "admin" && Object.keys(admin).length == 0) return;
      return axios
        .post(this.back_domen + "/service/stablepay", {
          year: this.year,
          month: this.month,
          shopID: this.access.any ? "all" : Object.keys(admin),
        })
        .then((res) => res.data)
        .then((json) => {
          this.data = this.update_data(json["response"].data);
          this.approval = json["response"].approval;
          this.working_hours = json.response.working_hours;
          if (
            this.approval.date &&
            String(new Date(this.approval.date)) != "Invalid Date"
          )
            this.approval.date = new Date(this.approval.date);
          if (this.approval.date && typeof this.approval.date == "string") {
            let date = this.approval.date.split(" ")[0];
            this.approval.date = new Date(date);
          }
          this.appCorrect = this.approval.approval || false;
          // console.log(this.approval);
        });
    },

    update_data(data) {
      // дополняем данные
      for (let shop of data) {
        for (let user of shop.users) {
          user.stable = [user.pay, ...user.payOther]
            .map((el) => el.pay)
            .reduce((a, b) => a + b);
        }
        if (shop.users.length)
          shop.stable = shop.users
            .map((el) => el.stable)
            .reduce((a, b) => a + b);
        else shop.stable = 0;
      }
      return data;
    },

    async add_correct() {
      // добавляем корректировку
      let data = {
        ...this.correct,
        year: this.year,
        month: this.month,
        email: this.all_shop_info[this.correct.shopID].email,
        date: new Date(),
        id: this.correct.user,
      };
      return axios
        .post(this.back_domen + "/service/update/correct", data)
        .then((res) => res.data)
        .then((json) => {
          if (json.response) {
            for (let el of this.data) {
              if (el.ID != data.shopID) continue;
              for (let el1 of el.users) {
                if (el1.id != data.id) continue;
                for (let el2 of [el1.pay, ...el1.payOther]) {
                  if (el2.shop != data.shopID) continue;
                  el2.corrections.push(data);
                  el2.pay += +data.amount;
                }
              }
            }
            this.data = this.update_data(this.data);
            if (this.approval.approval) {
              this.approval.approval = false;
              this.add_approval();
            }
          }
        });
    },

    async add_approval() {
      // добавляем согласование
      this.approval = {
        ...this.approval,
        date: this.approval.date || new Date(),
        user: this.approval.user || this.username,
        username: this.approval.username || this.fullname,
      };
      let data = { ...this.approval, year: this.year, month: this.month };
      // console.log(data);
      return axios
        .post(this.back_domen + "/service/update/approval", data)
        .then((res) => res.data)
        .then((json) => {
          if (json.response) this.appCorrect = this.approval.approval;
        });
    },

    add_work_hours() {
      // поочередно отправляем запрос на изменение времени
      for (let el of this.check_work_hours) {
        this.add_hours(el).then(() =>
          +this.month == el.month && +this.year == el.year
            ? this.get_data()
            : ""
        );
      }
    },

    async add_hours(data) {
      // отправляем рабочее время
      return axios
        .post(this.back_domen + "/service/update/whours", data)
        .then((res) => res.data)
        .then((json) => {
          if (json.response) {
            this.working_hours = this.working_hours.filter(
              (el) => +el.month != data.month || +el.year != data.year
            );
            if (data.hours) this.working_hours.push(data);
            else {
              delete this.working_hours_new[[data.month, data.year]];
              this.working_hours_new = { ...this.working_hours_new };
            }
          }
        });
    },

    checkValue(value) {
      // проверяем чтобы данные были числом
      if (isNaN(+value)) return value.slice(0, value.length - 1);
      return value;
    },
  },

  watch: {
    show_state_w() {
      this.local_month = this.month;
      this.local_year = this.year;
    },

    month() {
      this.get_data();
    },

    year() {
      this.get_data();
    },

    shop() {
      this.get_data();
    },

    dialog() {
      if (this.dialog && this.itemsShops.length == 1)
        this.correct.shopID = this.itemsShops[0];
      else if (!this.dialog) {
        this.correct = {
          amount: "",
          describe: "",
          shopID: "",
          user: undefined,
        };
        this.itemsShops = [];
      }
    },

    working_hours() {
      for (let el of this.working_hours) {
        if (!this.working_hours_new[[+el.month, +el.year]])
          this.working_hours_new[[+el.month, +el.year]] = el.hours;
      }
    },
  },

  created: function () {
    this.get_data();
  },
};
</script>
