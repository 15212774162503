var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{staticClass:"pa-0 d-flex",staticStyle:{"filter":"drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.7))"},attrs:{"app":"","color":"grey3"}},[_vm._l(([_vm.extra_seller, _vm.main]),function(menu,id){return _c('div',{key:id},[(_vm.extra || id == 1)?_c('div',{staticClass:"d-flex MainBottom grey3",class:id == 1 ? 'MainBottom' : 'ExtraBottom',attrs:{"name":"main_menu"}},_vm._l((menu),function(el){return _c('button',{key:el.key,class:el.key == _vm.active_button ? 'grey5--text' : 'white--text',style:(el.key == _vm.active_button
            ? `width: ${100 / menu.length}%; opacity: 0.4;`
            : `width: ${100 / menu.length}%;`),on:{"click":function($event){return _vm.click_menu(el.key)}}},[_c('v-icon',{staticClass:"white--text",class:el.key == _vm.active_button ? 'grey5--text' : 'white--text',domProps:{"textContent":_vm._s(
            _vm.extra && el.icon_active
              ? el.icon_active
              : el.icon
              ? el.icon
              : 'fas fa-ellipsis-h'
          )}}),_c('div',{staticClass:"text-12 font-weight-thin",staticStyle:{"text-transform":"capitalize"},domProps:{"textContent":_vm._s(el.name)}},[_vm._v(" ??? ")])],1)}),0):_vm._e()])}),_c('div',{staticClass:"d-flex justify-space-around text-10 grey2--text font-weight-light justify-center",staticStyle:{"width":"100%"}},[_c('div',[_vm._v("v."+_vm._s(_vm.version ? _vm.version : "local"))]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("oboi.ru©")]),_c('div',{staticClass:"ml-1"},[_vm._v(_vm._s(new Date().getFullYear()))])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }