var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.shiftdata.length == 0)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}}),_c('v-col',{attrs:{"cols":"7"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item, list-item"}})],1)],1),_c('v-skeleton-loader',{attrs:{"type":"list-item, list-item, list-item"}})],1):_c('div',[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[(_vm.shiftdata.length)?_c('v-simple-table',{staticStyle:{"background":"transparent !important"}},[[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.daystable),function(day){return _c('th',{key:day[0] + Math.random(),staticClass:"pa-0 text-center",class:_vm.calendar[day[0]] && _vm.calendar[day[0]].work == 'non-working'
                    ? 'orangeDark--text'
                    : ''},[_c('div',[_vm._v(_vm._s(day[0]))]),_c('div',[_vm._v(_vm._s(day[1]))])])})],2)]),_c('tbody',{staticClass:"text-left"},_vm._l((_vm.shiftdata),function(el){return _c('tr',{key:el.id},[_c('td',{staticClass:"pa-0"},[_c('div',{staticStyle:{"width":"140px"}},[_c('div',{staticStyle:{"position":"absolute"}},[_c('div',{staticStyle:{"position":"relative","z-index":"3","bottom":"-2px","-webkit-transform":"translate3d(0, 0, 0)","-webkit-overflow-scrolling":"touch"}},[_c('v-badge',{staticStyle:{"position":"absolute","right":"5px"},attrs:{"bordered":"","content":Object.values(el.shift).filter(
                            (el) =>
                              el.status != 'pass' && el.status != 'virtual'
                          ).length > 0
                            ? Object.values(el.shift).filter(
                                (el) =>
                                  el.status != 'pass' &&
                                  el.status != 'virtual'
                              ).length
                            : '',"color":"grey4 white--text"}}),_c('div',{staticClass:"white px-1 rounded-r-lg d-flex text-10",class:_vm.user && _vm.user.id == el.id
                            ? 'orangeDark--text'
                            : 'grey2--text'},[_c('div',[_vm._v(" "+_vm._s(el.name.split(" ")[0])+" ")]),(~el.name.indexOf(' '))?_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(el.name.split(" ")[1])+" ")]):_vm._e()])],1)])])]),_vm._l((_vm.daystable),function(day){return _c('td',{key:day[0] + Math.random(),staticClass:"pa-0 ma-0 td_clear"},[_c('v-card',{staticStyle:{"margin":"1px"},attrs:{"flat":"","height":"33","width":"33"}},[(
                      el.shift[day[0]] && el.shift[day[0]].status != 'virtual'
                    )?_c('v-chip',{class:el.shift[day[0]].responsible
                        ? 'marker'
                        : el.shift[day[0]].othercity
                        ? 'marker_travel'
                        : '',style:({
                      'box-shadow': `${
                        el.vocation[day[0]] ? 'inset 0 0 8px #FF6B6C' : 'none'
                      }`,
                      'background-color': `${
                        _vm.get_color(el.shift[day[0]]) == 'blueLight'
                          ? '#868EAA'
                          : '#B4BACE'
                      }`,
                    }),attrs:{"label":""}},[(el.shift[day[0]].hours)?_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.get_icon(el.shift[day[0]].hours))+" ")]):(el.shift[day[0]].responsible)?_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" star ")]):(el.shift[day[0]].othercity)?_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" flight ")]):(
                        !el.shift[day[0]].sellingMoney &&
                        el.shift[day[0]].status == 'open' &&
                        el.position == 'seller'
                      )?_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" far fa-lemon ")]):(!el.shift[day[0]].hours)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" t")]):_vm._e()],1):(el.vocation[day[0]])?_c('v-chip',{staticStyle:{"width":"100%"},attrs:{"label":"","color":_vm.get_color(el.vocation[day[0]])}}):_c('v-chip',{staticClass:"calendar[day[0]] && calendar[day[0]].work == 'non-working' ? 'orange' : 'grey1'",staticStyle:{"width":"100%"},attrs:{"label":"","outlined":!el.hours &&
                      (_vm.access.any == 'admin' || _vm.access[_vm.shop] == 'admin') &&
                      _vm.check_date(day[0])
                        ? true
                        : false}})],1)],1)})],2)}),0)]],2):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }