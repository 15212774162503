var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white pb-12",staticStyle:{"position":"absolute","width":"100%","min-height":"100%"},attrs:{"rounded":"0","flat":""}},[(Object.keys(_vm.active_product).length > 0)?_c('div',[_c('SaleProductAbout',{attrs:{"product":_vm.active_product}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-rows orangeMain--text px-3 pt-2",staticStyle:{"position":"absolute"}},[(_vm.count)?_c('span',[_vm._v(_vm._s(_vm.count)+" x "+_vm._s(_vm._f("money")(_vm.active_product.Цена))+" = "+_vm._s(_vm._f("money")((_vm.active_product.Цена * _vm.count))))]):_vm._e()]),_c('div',{staticClass:"pa-2"},[_c('v-text-field',{staticClass:"my-6 rounded-xl rounded-tl-0",attrs:{"outlined":"","hide-details":"","autofocus":_vm.copy_products.lenth > 0 ? true : false,"id":"search","label":_vm.headers.join(' - ').toLowerCase(),"maxlength":"20","color":"orangeMain"},on:{"input":function($event){_vm.active_product = {}}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}},[(_vm.search)?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.search),expression:"search"}],attrs:{"slot":"append","color":"grey4"},on:{"click":function($event){_vm.search = '';
          _vm.count = '';}},slot:"append"},[_vm._v("fas fa-backspace")]):_vm._e()],1),(
        Object.keys(this.active_product).length == 0 &&
          _vm.search_products.length > 0 &&
          _vm.full_products.filter(el => (el.Артикул+el.ТорговаяМарка+el.Коллекция).indexOf(_vm.search) != -1).length > 0
      )?_c('v-simple-table',{staticClass:"px-md-12 py-4",attrs:{"color":"grey6"}},[[_c('thead',[_c('tr',[_vm._l(([..._vm.headers, ..._vm.headers_add]),function(el){return _c('th',{key:el,staticClass:"text-center grey4--text"},[_vm._v(" "+_vm._s(_vm.good_title[el] || el.toLowerCase())+" ")])}),_c('th')],2)]),_c('tbody',{staticClass:"text-center"},_vm._l((_vm.full_products.filter(el => (el.Артикул+el.ТорговаяМарка+el.Коллекция).indexOf(_vm.search) != -1)),function(el){return _c('tr',{key:el.Артикул,on:{"click":function($event){_vm.active_product = el}}},[_vm._l(([..._vm.headers, ..._vm.headers_add]),function(el1){return _c('td',{key:el1,attrs:{"width":el == 'Коллекция' ? '40%' : ''}},[_c('a',{staticClass:"black--text"},[_vm._v(" "+_vm._s(el[el1] ? el[el1] : "")+" ")])])}),_c('td',[(el.Бонус)?_c('v-img',{attrs:{"contain":"","width":"20px","src":_vm.$root.coin}}):_vm._e()],1)],2)}),0)]],2):_vm._e(),(
        Object.keys(_vm.active_product).length > 0 &&
          _vm.$route.name == 'Sale' &&
          _vm.user.workNow == 'open' &&
          _vm.user.seller
      )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-row',{staticClass:"ml-md-2"},_vm._l((6),function(i){return _c('v-col',{key:'row1' + i,attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"rounded-xl transparent",attrs:{"height":"40"},on:{"click":function($event){_vm.count = i}}},[_vm._v(_vm._s(i))])],1)}),1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-xl rounded-tl-0",attrs:{"outlined":"","hide-details":"","color":"orangeMain","label":"количество","maxlength":"3"},on:{"keydown":function($event){return _vm.check_number($event)}},model:{value:(_vm.count),callback:function ($$v) {_vm.count=$$v},expression:"count"}},[(_vm.count)?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.count),expression:"count"}],attrs:{"slot":"append","color":"grey4"},on:{"click":function($event){_vm.count = ''}},slot:"append"},[_vm._v("fas fa-backspace")]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mt-n4 rounded-xl transparent",attrs:{"height":"40"},on:{"click":function($event){_vm.count > 1 ? _vm.count-- : (_vm.count = '')}}},[_vm._v("-")])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"ml-n2 ml-md-n0 mt-n4 rounded-xl transparent",attrs:{"height":"40"},on:{"click":function($event){_vm.count++}}},[_vm._v("+")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"6","md":"12"}},[(_vm.$route.name == 'Sale')?_c('v-btn',{staticClass:"rounded-xl transparent",staticStyle:{"box-shadow":"0px 0px 2px red"},attrs:{"large":"","width":"100%","disabled":_vm.count == 0 || false},on:{"click":function($event){_vm.count = -_vm.count;
                _vm.add_sale();}}},[_c('span',{staticClass:"text-18"},[_vm._v("возврат")])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"6","md":"12"}},[_c('v-btn',{staticClass:"rounded-xl transparent",staticStyle:{"box-shadow":"0px 0px 2px green"},attrs:{"large":"","width":"100%","disabled":_vm.count == 0 || false},on:{"click":function($event){return _vm.add_sale()}}},[_c('span',{staticClass:"text-18"},[_vm._v(" продажа ")])])],1)],1)],1)],1):_vm._e(),_c('v-btn',{staticClass:"rounded-0 transparent grey2 orangeMain--text",staticStyle:{"position":"fixed","bottom":"0px","left":"0px"},attrs:{"elevation":"0","large":"","width":"100%"},on:{"click":function($event){return _vm.$emit('close_dialog')}}},[_c('v-icon',{staticClass:"mx-3",attrs:{"large":""}},[_vm._v(" mdi-keyboard-return ")]),_c('span',{staticClass:"text-18"},[_vm._v(" закрыть ")])],1)],1),_c('v-snackbar',{attrs:{"top":"","right":"","timeout":1500,"color":~_vm.message.indexOf('ошибка')
        ? 'error'
        : ~_vm.message.indexOf('возврат')
        ? 'warning'
        : 'success'},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.addsale = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"small":""}},[_vm._v("far fa-trash-alt")])],1)]}}]),model:{value:(_vm.addsale),callback:function ($$v) {_vm.addsale=$$v},expression:"addsale"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }