var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"red mx-0",attrs:{"id":"app"}},[(
      _vm.$vuetify.breakpoint.name == 'xs' &&
        !Object.values(_vm.access).includes('admin')
    )?_c('v-app',[(!_vm.login_show)?_c('AppAvatar',{staticClass:"ml-2 mt-2",attrs:{"change_theme":"change_theme"}}):_vm._e(),_c('v-main',[_c('v-container',{staticClass:"px-1",staticStyle:{"padding-bottom":"80px","height":"100%"},attrs:{"fluid":""}},[_c('router-view')],1)],1),_c('Footer2022'),(_vm.login_show)?_c('Login',{attrs:{"login_dialog":_vm.login_show,"email_enabled":true,"color_background":"white","color_main":"blueMain","color_text_main":"white","color_second":"white","color_text_second":"blueMain","button_height":"50"},on:{"finish":_vm.close_login_dialog}}):_vm._e()],1):(
      // username == '+79106894492' ||
      // username == '+71109992412' ||
      _vm.username == 'Artur.Kalinin@erismann.ru' ||
        _vm.username == 'Aleksey.Grabovskiy@erismann.ru' ||
        _vm.username == 'vog@oboi.ru' ||
        _vm.username == 'stenova@oboi.ru' ||
        _vm.username == 'ag-group@oboi.ru' ||
        _vm.username == 'sale_msk@ag-group.com.ru' ||
        _vm.username == 'arteks@oboi.ru'
    )?_c('v-app',[_c('NavigationBar'),_c('v-main',[_c('v-container',{staticClass:"pa-0 mb-0",staticStyle:{"padding-bottom":"80px","height":"100.7%"},attrs:{"fluid":""}},[_c('router-view')],1)],1),(_vm.login_show)?_c('Login',{attrs:{"login_dialog":_vm.login_show,"email_enabled":true,"color_background":"white","color_main":"blueMain","color_text_main":"white","color_second":"white","color_text_second":"blueMain","button_height":"50"},on:{"finish":_vm.close_login_dialog}}):_vm._e()],1):_c('v-app',[_c('Header'),_c('router-view',{staticClass:"px-1"}),_c('Footer'),_c('v-snackbar',{attrs:{"top":"","timeout":"-1","multi-line":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":"","color":"orangeMain"},on:{"click":function($event){return _vm.change_show_help('')}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("clear")])],1)]}}]),model:{value:(_vm.showHelp),callback:function ($$v) {_vm.showHelp=$$v},expression:"showHelp"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.help[_vm.show_help])}})]),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.open_picture),callback:function ($$v) {_vm.open_picture=$$v},expression:"open_picture"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"}),_c('v-card-text',{staticClass:"pa-1"},[_c('v-carousel',_vm._l((Object.entries(_vm.pic_art).reduce(
                (acc, [k, v]) =>
                  ~k.indexOf('img') && v.s800x800
                    ? [...acc, v.s800x800]
                    : acc,
                []
              )),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item,"reverse-transition":"fade-transition","transition":"fade-transition"}})}),1)],1),_c('v-card-actions',{staticClass:"pa-0"})],1)],1),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.open_shop),callback:function ($$v) {_vm.open_shop=$$v},expression:"open_shop"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"}),_c('v-card-text',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.all_shop_info[_vm.shopID_show])+" ")]),_c('v-card-actions',{staticClass:"pa-0"})],1)],1),(_vm.login_show)?_c('Login',{attrs:{"login_dialog":_vm.login_show,"email_enabled":true,"color_background":"white","color_main":"blueMain","color_text_main":"white","color_second":"white","color_text_second":"blueMain","button_height":"50"},on:{"finish":_vm.close_login_dialog}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }